import React from 'react';
import {
  WebDevSection, Container, FlexWrapper,
  ImageColumn, ImageContainer, StyledImage,
  ContentColumn, ContentBox, ContentSection,
  SectionTitle, SectionText
} from './WebdevElements';

const webDImage = require('../../images/webd11.png');

const WebDev = () => {
  return (
    <WebDevSection id="webdev">
      <Container>
        <FlexWrapper>
          <ImageColumn>
            <ImageContainer>
              <StyledImage
                src={webDImage}
                alt="web development"                
              />              
            </ImageContainer>
          </ImageColumn>

          <ContentColumn>
            <ContentBox>
              <ContentSection>
                <SectionTitle>Modern Web Development</SectionTitle>
                <SectionText>
                Development of websites and online stores is provided
                using tools like React/Next.js and the MERN stack
                </SectionText>
              </ContentSection>

              <ContentSection>
                <SectionTitle>Full-Scale Integration</SectionTitle>
                <SectionText>
                From basic landing pages to full-featured online stores,
                solutions are crafted to connect seamlessly with social
                media accounts and online services through APIs
                </SectionText>
              </ContentSection>

              <ContentSection>
                <SectionTitle>Advanced Solutions</SectionTitle>
                <SectionText>
                Custom features, AI agent development, and ongoing
                support are offered to enhance functionality and performance
                </SectionText>
              </ContentSection>
            </ContentBox>
          </ContentColumn>
        </FlexWrapper>
      </Container>
    </WebDevSection>
  );
};

export default WebDev;