import React from 'react';
import { SidebarContainer, Icon, 
CloseIcon, SidebarWrapper, SidebarMenu, SidebarLink } from './SidebarElements';

const Sidebar = ({ isOpen, toggle }) => {
    

    return (        
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to="about" onClick={toggle} offset={0}>About Us</SidebarLink>
                    <SidebarLink to="devmodules" onClick={toggle} offset={0}>Dev modules</SidebarLink>
                    <SidebarLink to="webdev" onClick={toggle} offset={0}>Web dev</SidebarLink>                                       
                    <SidebarLink to="services" onClick={toggle} offset={0}>Services</SidebarLink>                    
                </SidebarMenu>                
            </SidebarWrapper>
        </SidebarContainer>        
    );
};

export default Sidebar;
