import React from 'react';
import AboutData from './Data';
import { AboutSection, AboutContainer,
AboutItem, AboutHeader, AboutIcon, AboutTitle,
AboutP, SectionTitle } from './AboutElements';

const About = () => {
  return (
    <AboutSection id="about">
      <SectionTitle>
        <h2>E-commerce solutions</h2>       
      </SectionTitle>           
      <AboutContainer>      
        {AboutData.map((item) => (
          <AboutItem key={item.id}>
            <AboutHeader>
            <AboutIcon>{item.icon}</AboutIcon>
            <AboutTitle>{item.title}</AboutTitle>
            </AboutHeader>
            <AboutP>{item.paragraph}</AboutP>
          </AboutItem>
        ))}
      </AboutContainer>
    </AboutSection>
  );
};

export default About;