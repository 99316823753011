import styled from 'styled-components';

export const DevMSection = styled.section`
  padding-top: 4rem;
  background-color: #9baebc;
  @media screen and (min-width: 768px) {
    padding-top: 5rem;
  }
  @media screen and (min-width: 1024px) {
    padding-top: 7rem;
  }
`;

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0 1rem;
  max-width: 1200px;
`;

export const ContentWrapper = styled.div`
 
  padding-bottom: 4rem;
  @media screen and (min-width: 768px) {
    padding-bottom: 5rem;
  }
  @media screen and (min-width: 1024px) {
    padding-bottom: 7rem;
  }
`;

export const ContentRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -1rem;
`;

export const Column = styled.div`
  width: 100%;
  padding: 0 1rem;
  
  @media screen and (min-width: 1024px) {
    width: 50%;
  }
`;

export const Title = styled.h2`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color:#0e303f;
`;

export const Paragraph = styled.p`
  margin-bottom: 2.75rem;
  color:#0e303f;
`;

export const ListContainer = styled.div`
  margin: 0 -0.75rem;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 3rem;
  @media screen and (min-width: 1024px) {
    margin-bottom: 0;
  }
`;

export const ListColumn = styled.div`
  width: 100%;
  padding: 0 0.75rem;
  @media screen and (min-width: 640px) {
    width: 50%;
  }
  @media screen and (min-width: 1024px) {
    width: 100%;
  }
  @media screen and (min-width: 1280px) {
    width: 50%;
  }
`;

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;
  font-size: 1.125rem;
  font-weight: 500;
  color:#0e303f;
`;

export const IconWrapper = styled.span`
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 6px;
  background-color: #1b2d37;
  color: #9baebc;
  transition: transform 0.4s ease;

  &:hover {
    transform: rotate(360deg); // Полный поворот
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  aspect-ratio: 3/2;
  max-width: 600px;
  display: flex;
  align-items: center;
  justify-content: center; 

  @media screen and (min-width: 1024px) {
    margin-right: 0;
  }
`;

export const StyledImage = styled.img`
  max-width: 70%;
  margin: auto;
  display: block; 
  filter: drop-shadow(rgba(0, 0, 0, 0.3) 10px 10px 5px);

  animation: pulse 7s infinite;
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.02); 
    }
    100% {
      transform: scale(1);
    }
  }
`;