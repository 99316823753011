import styled from "styled-components";

export const HeroContainer = styled.div`
    background: #243545;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 70vh;
    position: relative;
    z-index: 1;
    width: 100%; 
   

    @media screen and (max-width: 768px) {
       
        padding: 200px 0 150px 0;
    }
`;

export const HeroBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

export const VideoOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 2;
`;

export const VideoBg = styled.video`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #232a34;    
`;

export const HeroContent = styled.div`
    z-index: 3;
    max-width: 1200px;
    position: absolute;
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;   
`;

export const HeroH1 = styled.h1`
    color: #fff;
    font-size: 40px;
    text-align: center;
    
    @media screen and (max-width: 768px) {
        font-size: 40px;        
    }

    @media screen and (max-width: 480px) {
        font-size: 32px;        
    }    
`;

export const HeroP = styled.p `
    margin-top: 20px;
    letter-spacing: 1.4px;
    color: #fff;
    font-weight: 500;
    font-size: 0.9rem;
    text-align: center;
    max-width: 600px;    

    @media screen and (max-width: 780px) {
        font-size: 0.8rem;        
    }
`;

export const HeroPIcons = styled.p `
    margin-top: 40px;
    display: flex;
    color: #fff; 
    justify-content: space-between;
    align-items: center;
    width: 140px;

    a {
        color: #fff; 
        text-decoration: none;
    }
`;
