import React from 'react'
import { FaLaptopCode } from 'react-icons/fa6';
import { FaTelegram } from 'react-icons/fa'
import { FaXTwitter } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { FooterContainer, FooterWrap, SocialMedia, SocialMediaWrap,
SocialLogo, WebsiteRights, SocialIcons, SocialIconLink } from './FooterElements'
import { animateScroll as scroll } from 'react-scroll'

const Footer = () => {
    const telegramUrl = process.env.REACT_APP_PUBLIC_TELEGRAM_URL;
    const xUrl = process.env.REACT_APP_PUBLIC_X_URL;
    const email = process.env.REACT_APP_PUBLIC_EMAIL;
    
    const handleEmailClick = (e) => {
        e.preventDefault();
        window.open(`mailto:${email}`, '_blank');
    };

    const toggleHome = () => {
        scroll.scrollToTop();
    };
    
    
    return (
        <FooterContainer>
            <FooterWrap>
                <SocialMedia>
                    <SocialMediaWrap>
                    <SocialLogo to='/' onClick={toggleHome}>
                        <FaLaptopCode size={28} style={{ marginRight: '8px' }} />
                    opdev                            
                        </SocialLogo>
                        <WebsiteRights> 
                        <a
                href="https://github.com/bvox-void"
                target="_blank"
                rel="noopener noreferrer"               
              >
                bvox{" "}                 
              </a>          
           © 2022-{new Date().getFullYear()}</WebsiteRights>
                        <SocialIcons>                        
                            <SocialIconLink href={telegramUrl}
                            target="_blank"
                            rel="noopener noreferrer" 
                            aria-label="Telegram">
                                <FaTelegram size={22}/>
                            </SocialIconLink>                           
                            <SocialIconLink href={xUrl}
                            target="_blank"
                            rel="noopener noreferrer" 
                            aria-label="X">
                                <FaXTwitter size={22}/>
                            </SocialIconLink>
                            <SocialIconLink href={`mailto:${email}`}
                            target="_blank"
                            rel="noopener noreferrer" 
                            aria-label="Email"
                            onClick={handleEmailClick}>
                                <MdEmail size={26}/>
                            </SocialIconLink>         
                        </SocialIcons>
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>            
        </FooterContainer>
    )
}

export default Footer
