import { 
  FaStore, 
  FaCode,
  FaReact, 
  FaPuzzlePiece, 
  FaLightbulb, 
  FaRobot 
} from 'react-icons/fa';

const AboutData = [
  {
    id: 1,
    icon: <FaStore size={40} style={{ color: '#00b4d8' }} />,
    title: 'Online stores',
    paragraph: `
      Full-cycle development of e-commerce projects using OpenCart. 
      Custom modules, payment integrations, and optimized checkout 
      processes for your online business.
    `
  },
  {
    id: 2,
    icon: <FaCode size={40} style={{ color: '#61dafb' }} />,
    title: 'Modern Development',
    paragraph: `
      Creating intuitive and responsive web solutions using modern 
      technologies. Our development approach ensures excellent 
      performance and user experience.
    `
  },
  {
    id: 3,
    icon: <FaReact size={40} style={{ color: '#61dafb' }} />,
    title: "React/Next.js",
    paragraph: `
      Built with cutting-edge React and Next.js technologies for optimal 
      performance. Dynamic rendering and server-side capabilities ensure 
      fast loading times and improved SEO rankings.
    `
  },
  {
    id: 4,
    icon: <FaPuzzlePiece size={40} style={{ color: '#40ae83' }} />,
    title: "Custom Modules",
    paragraph: `
      Development of custom OpenCart modules and React components for 
      your specific business needs. Integration with external services 
      and APIs for enhanced functionality.
    `
  },
  {
    id: 5,
    icon: <FaLightbulb size={40} style={{ color: '#ffd60a' }} />,
    title: "Innovative Solutions",
    paragraph: `
      Every component and feature is tailored to match your business 
      requirements. Our modular architecture allows easy modifications 
      and additions.
    `
  },
  {
    id: 6,
    icon: <FaRobot size={40} style={{ color: '#7209b7' }} />,
    title: "AI Integration",
    paragraph: `
      Implementation of AI-powered features and chatbots to enhance your 
      business processes. Automation of customer support and business 
      operations for improved efficiency.
    `
  }
];

export default AboutData;