import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom';
import { Link as LinkS } from 'react-scroll';

export const Nav = styled.nav`   
    background: transparent;
    height: 65px;
    margin-top: -65px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;    
    text-transform: uppercase;
    position: sticky;
    top: 0;
    z-index: 10;
    transition: background 0.3s ease-in-out;
    width: 100%; 

    @media screen and (max-width: 960px) {
        transition: 0.8s all ease;
    }
`;

export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-between; 
    align-items: center; 
    height: 50px;
    z-index: 1;
    width: 100%;
    max-width: 1200px;
    background: rgba(135, 176, 205, 0.3);
    border-radius: 15px;
    padding: 0 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative; 

    @media screen and (max-width: 480px) {
        padding: 0 24px;
    }
`;

export const NavLogo = styled(LinkR)`
    color: #fff;
    justify-self: flex-start;
    cursor: pointer;
    font-size: 1.4rem;
    display: flex;
    align-items: center;    
    font-weight: bold;
    text-decoration: none;
    gap: 2px;
    
    
    @media only screen and (max-width: 480px) {
    margin-left: 0px;
    font-size: 1.2rem;
}
`;

export const MobileIcon = styled.div`
    display: none;

    @media screen and (max-width: 768px) {
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(-100%, -50%);
        font-size: 1.5rem;
        cursor: pointer;
        color: #fff;
    }
`;

export const NavMenu = styled.ul`
    
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    margin: 0 auto; 
    padding: 0;
    transform: translateX(-40px);   

    @media screen and (max-width: 768px) {
        display: none;
    }
`;

export const NavItem = styled.li`
    height: 50px;
`;

export const NavLinks = styled(LinkS)`
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-weight: 500;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;

    &:active {
        border-bottom: 2px solid #8ec3ce;        
    }
`;

export const NavLngSwitcher = styled.div`
    color: #fff;
    display: flex;
    align-items: center;    
    text-decoration: none;
    padding: 0 0rem;
    height: 100%;    
    
    @media screen and (max-width: 768px) {
       margin-right: 45px;              
    }
`;

