import styled from 'styled-components';
import { Link as LinkS } from 'react-scroll';
import { FaTimes } from 'react-icons/fa';

export const SidebarContainer = styled.aside`
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 35vh;
    background-color: rgba(44, 62, 80, 0.9);
    display: grid;
    align-items: center;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;
    opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
    transform: ${({ isOpen }) => (isOpen ? 'translateY(0)' : 'translateY(-100%)')};
    border-bottom-left-radius: 15px; 
    border-bottom-right-radius: 15px; 

    @media screen and (max-width: 680px) and (min-width: 480px) {
        width: 100%;
        height: 50vh;   
    }
    
`;

export const CloseIcon = styled(FaTimes)`
    color: #fff;
    font-size: 1.5rem;    
`;

export const Icon = styled.div`
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
    outline: none;

    @media screen and (max-width: 680px) and (min-width: 480px) {
        top: 1rem; 
        right: 1rem;
    }
`;

export const SidebarWrapper = styled.div`
    color: #fff;
`;

export const SidebarMenu = styled.ul`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 30px);
    text-align: center;

    @media screen and (max-width: 768px) and (min-width: 481px) {       
        grid-template-rows: repeat(4, 60px); 
    }

    @media screen and (max-width: 480px) {
        grid-template-rows: repeat(4, 40px);
    }

`;

export const SidebarLink = styled(LinkS)`
    display: flex;    
    justify-content: center;
    font-size: 1rem;
    font-weight: 500;
    text-decoration: none;
    text-transform: uppercase;
    list-style: none;
    transition: 0.2s ease-in-out;
    text-decoration: none;
    color: #fff;
    cursor: pointer;

    &:hover {
        color: #8ec3ce;
        transition: 0.2s ease-in-out;
            }
`;

