import styled from 'styled-components';

export const WebDevSection = styled.section`
  padding: 4rem 0;
  background-color: #1c2d38;

   //svg
   @media (min-width: 768px) {  
  background-image: url('/about.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-blend-mode: soft-light;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(28, 45, 56, 0.83);
    z-index: 1;
  }

  > * {
    position: relative;
    z-index: 2;
  }
} //end svg

  @media (min-width: 768px) {
    padding: 5rem 0;
  }
  @media (min-width: 1024px) {
    padding: 7rem 0;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
`;

export const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -1rem;
`;

export const ImageColumn = styled.div`
  width: 100%;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  
    @media (min-width: 1024px) {
    width: 50%;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  margin: 0 auto 3rem;
  max-width: 500px;
  aspect-ratio: 25/24;
  display: flex;
  align-items: center;
  justify-content: center;    
  

  @media (min-width: 1024px) {
    margin: 0;
  }
`;
//681x681 norm size
export const StyledImage = styled.img`
  max-width: 85%;
  height: auto;
  filter: drop-shadow(rgba(0, 0, 0, 0.3) 10px 10px 5px);
  display: block;
  position: relative;
  z-index: 2;

  animation: pulse 7s infinite;
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.02); // Легкое увеличение
    }
    100% {
      transform: scale(1);
    }
  }
`;

export const ContentColumn = styled.div`
  width: 100%;
  padding: 0 1rem;
  @media (min-width: 1024px) {
    width: 50%;
  }
`;

export const ContentBox = styled.div`
  max-width: 470px;
`;

export const ContentSection = styled.div`
  margin-bottom: 2.25rem;
  &:last-child {
    margin-bottom: 0.25rem;
  }
`;

export const SectionTitle = styled.h3`
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff;  
  
`;

export const SectionText = styled.p`
  font-size: 0.9rem;
  font-weight: 500;  
  color: #b5c7d6;
`;