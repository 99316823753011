import React from "react";
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { SpeedInsights } from "@vercel/speed-insights/react";
import Home from './pages';
import DynamicFavicon from './components/DynamicFavicon';

function App() {
  
  return (
    <>
      <DynamicFavicon />
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />     
        </Routes>      
        <SpeedInsights />
      </Router>
    </>
  );
}

export default App;
