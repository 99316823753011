import React from 'react';
import { FaShoppingCart, FaCreditCard, FaBoxOpen, FaShippingFast, FaPlug, FaChartLine } from 'react-icons/fa';
import {
  DevMSection, Container, ContentWrapper,
  ContentRow, Column, Title, Paragraph,
  ListContainer, ListColumn, ListItem,
  IconWrapper, ImageWrapper, StyledImage  
} from './DevMElements';

const devMImage = require('../../images/devm5.png');

const DevModules = () => {
  const ListItems = [
    { icon: <FaShoppingCart size={22} />, text: "Custom Cart Systems" },
    { icon: <FaCreditCard size={22} />, text: "Payment Integration" },
    { icon: <FaBoxOpen size={22} />, text: "Inventory Management" },
    { icon: <FaShippingFast size={22} />, text: "Order Processing" },
    { icon: <FaPlug size={22} />, text: "API Integration" },
    { icon: <FaChartLine size={22} />, text: "Store Analytics" }
  ];

  const List = ({ icon, text }) => (
    <ListItem>
      <IconWrapper>
        {icon}
      </IconWrapper>
      {text}
    </ListItem>
  );

  return (
    <DevMSection id="devmodules">
      <Container>
        <ContentWrapper>
          <ContentRow>
            <Column>
              <Title>E-commerce solutions for your business growth</Title>
              <Paragraph>
                Our primary focus is developing scalable e-commerce modules that help businesses streamline their online operations through innovative solutions and cutting-edge technologies.
              </Paragraph>

              <ListContainer>
                <ListColumn>
                  <List icon={ListItems[0].icon} text={ListItems[0].text} />
                  <List icon={ListItems[1].icon} text={ListItems[1].text} />
                  <List icon={ListItems[2].icon} text={ListItems[2].text} />
                </ListColumn>

                <ListColumn>
                  <List icon={ListItems[3].icon} text={ListItems[3].text} />
                  <List icon={ListItems[4].icon} text={ListItems[4].text} />
                  <List icon={ListItems[5].icon} text={ListItems[5].text} />
                </ListColumn>
              </ListContainer>
            </Column>

            <Column>
              <ImageWrapper>                
                <StyledImage
                  src={devMImage}
                  alt="e-commerce-modules"                               
                />
              </ImageWrapper>
            </Column>
          </ContentRow>
        </ContentWrapper>
      </Container>
    </DevMSection>
  );
};

export default DevModules;