import React from 'react';
import VideoMP4 from '../../videos/v3.mp4';
import VideoWebM from '../../videos/v3.webm';
import Typewriter from 'typewriter-effect';
import { 
  HeroContainer,HeroBg,VideoBg, 
  VideoOverlay, HeroContent, HeroH1, 
  HeroP, HeroPIcons 
} from './HeroElements';
import { FaTelegram } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";

const Hero = () => {
    const telegramUrl = process.env.REACT_APP_PUBLIC_TELEGRAM_URL;
    const xUrl = process.env.REACT_APP_PUBLIC_X_URL;
    const email = process.env.REACT_APP_PUBLIC_EMAIL;

    const handleEmailClick = (e) => {
        e.preventDefault();
        window.open(`mailto:${email}`, '_blank');
    };
    
    return (
        <HeroContainer>
            <HeroBg>                
                <VideoBg autoPlay playsInline loop muted preload="auto">
                    <source type="video/mp4" src={VideoMP4} />
                    <source type="video/webm" src={VideoWebM} />
                    Your browser does not support the video tag.
                </VideoBg>
                <VideoOverlay /> 
            </HeroBg>
            <HeroContent>
                <HeroH1>
                <Typewriter
                    options={{
                    strings: ['Online stores', 'API & data sync', 'E-commerce'],
                    autoStart: true,
                    loop: true,
                    delay: 75,
                    deleteSpeed: 50,
                    pauseFor: 7000,
                    cursor: '|'      
                    
                    }}
                />
                </HeroH1>
                <HeroP>
                    E-commerce solutions | API & data sync | OpenCart, React/Next.js | Building scalable stores & custom integrations
                </HeroP>
                <HeroPIcons>
                    <a href={telegramUrl} target="_blank" rel="noopener noreferrer" aria-label="Telegram">
                        <FaTelegram size={20}/>
                    </a>
                    <a href={xUrl} target="_blank" rel="noopener noreferrer" aria-label="X">
                        <FaXTwitter size={20}/>
                    </a>
                    <a href={`mailto:${email}`} target="_blank" rel="noopener noreferrer" aria-label="Email" onClick={handleEmailClick}>
                        <MdEmail size={24}/>
                    </a>
                </HeroPIcons>
            </HeroContent>            
        </HeroContainer>
    );
};

export default Hero;