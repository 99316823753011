import { 
  FaStore, 
  FaReact, 
  FaPuzzlePiece, 
  FaRobot 
} from 'react-icons/fa';

export const ServicesData = [
  {
    id: 1,
    title: "E-commerce Dev",
    frontDescription: "Custom online store development solutions",
    backDescription: "Full-cycle development of e-commerce projects using OpenCart. Custom modules, payment integrations, and optimized checkout processes.",
    icon: <FaStore size={50} style={{ color: '#00b4d8' }} />
  },
  {
    id: 2,
    title: "React Applications",
    frontDescription: "Modern web applications with React",
    backDescription: "Building scalable web applications using React and Next.js. Focus on performance, SEO, and exceptional user experience.",
    icon: <FaReact size={50} style={{ color: '#61dafb' }} />
  },
  {
    id: 3,
    title: "Custom Modules",
    frontDescription: "Tailored solutions for your business",
    backDescription: "Development of custom OpenCart modules and React components. Integration with external services and APIs.",
    icon: <FaPuzzlePiece size={50} style={{ color: '#40ae83' }} />
  },
  {
    id: 4,
    title: "AI Integration",
    frontDescription: "Smart solutions for your business",
    backDescription: "Implementation of AI-powered features and chatbots. Automation of business processes and customer support.",
    icon: <FaRobot size={50} style={{ color: '#fff' }} />
  }
];