import React from 'react';

const DynamicFavicon = () => {
  React.useEffect(() => {
    let favicon = document.querySelector('link[rel="icon"]');    
   
    if (!favicon) {
      favicon = document.createElement('link');
      favicon.rel = 'icon';
      document.head.appendChild(favicon);
    }

    const svg = `
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'>
        <rect width='100' height='100' rx='20' fill='#1c2d38'/>
        <path d='M50 25a25 25 0 1 0 25 25 25 25 0 0 0-25-25zm8.3 37.5h-5.8v5.8a2.5 2.5 0 0 1-5 0v-5.8h-5.8a2.5 2.5 0 0 1 0-5h5.8v-5.8a2.5 2.5 0 0 1 5 0v5.8h5.8a2.5 2.5 0 0 1 0 5z' fill='#61dafb'/>
      </svg>
    `;
    const encodedSvg = encodeURIComponent(svg);
    favicon.href = `data:image/svg+xml,${encodedSvg}`;
  }, []);

  return null;
};

export default DynamicFavicon;