import React, { useState } from 'react';
import { 
  ServicesSection, 
  ServicesTitle, 
  ServicesContainer,
  CardContainer,
  ServiceCard,
  CardFront,
  CardBack,
  ServiceIcon,
  ServiceTitle,
  ServiceDescription,
  ClickHint
} from './ServicesElements';
import { ServicesData } from './Data';
import { FaArrowRight } from 'react-icons/fa';

const Services = () => {
  const [flippedCards, setFlippedCards] = useState({});

  const handleCardClick = (id) => {
    setFlippedCards(prev => ({
      ...prev,
      [id]: !prev[id]
    }));
  };

  return (
    <ServicesSection id="services">
      <ServicesTitle>
        <h2>Our services</h2>        
      </ServicesTitle>
      <ServicesContainer>
        {ServicesData.map((service) => (
          <CardContainer key={service.id} onClick={() => handleCardClick(service.id)}>
            <ServiceCard isFlipped={flippedCards[service.id]}>
              <CardFront>
                <ServiceIcon>
                  {service.icon}
                </ServiceIcon>
                <ServiceTitle>{service.title}</ServiceTitle>
                <ServiceDescription>
                  {service.frontDescription}
                </ServiceDescription>
                <ClickHint>
                  Learn more <FaArrowRight size={12} />
                </ClickHint>
              </CardFront>
              <CardBack>
                <ServiceTitle>{service.title}</ServiceTitle>
                <ServiceDescription>
                  {service.backDescription}
                </ServiceDescription>
              </CardBack>
            </ServiceCard>
          </CardContainer>
        ))}
      </ServicesContainer>
    </ServicesSection>
  );
};

export default Services;