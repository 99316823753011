import styled from 'styled-components';

export const AboutSection = styled.section`
  width: 100%;
  background-color: #1c2d38;
  padding-top: 4rem; 
  padding-bottom: 4rem; 
  display: flex;
  flex-direction: column;
  align-items: center;

   //svg
   @media (min-width: 768px) {  
  background-image: url('/about.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-blend-mode: soft-light;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(28, 45, 56, 0.83);
    z-index: 1;
  }

  > * {
    position: relative;
    z-index: 2;
  }
} //end svg

  @media (min-width: 768px) {
    padding-bottom: 5rem; 
  }
  @media (min-width: 1024px) {
    padding-bottom: 7rem; 
  }
`;

export const SectionTitle = styled.div`
  text-align: center;
  margin-bottom: 70px;
  width: 100%;

  h2 {
    font-size: 2rem;
    color: #fff;
  }

  p {
    font-size: 1rem;
    color: #9baebc;
  }
`;

export const AboutContainer = styled.div`
  max-width: 1200px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 3.5rem 2rem;
  
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const AboutItem = styled.div`
  width: 100%;  
  margin-bottom: 20px;
  text-align: center;
  padding: 0 1rem;
   
  .wow {
    animation-name: fadeInUp;
    animation-delay: 0.15s;
  }
  @media (min-width: 768px) {
    padding: 0; 
  }
`;

export const AboutHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
`;

export const AboutIcon = styled.div`
  margin-bottom: 10px;
  display: flex;
  height: 70px;
  width: 70px;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;
  background-color: rgba(44, 62, 80, 0.7);
  color: #9baebc;
  filter: drop-shadow(2px 2px 2px rgba(0,0,0,0.3));
  svg {
    fill: currentColor;
  }
  transition: transform 0.4s ease;

  &:hover {
    transform: rotate(360deg); // Полный поворот
  }
`;

export const AboutTitle = styled.h3`
  margin-left: 15px; 
  font-size: 1.1rem; 
  color: #fff;

  @media (min-width: 640px) {
    font-size: 1.5rem;
  }
  @media (min-width: 1024px) {
    font-size: 1.2rem;
  }
  @media (min-width: 1280px) {
    font-size: 1.5em;
  }
`;

export const AboutP = styled.p`
  font-size: 0.9rem;
  color: #b5c7d6;
  padding: 30px 10px 30px 10px; 
  font-weight: 500;
  line-height: 1.75;
  border-radius: 0.5rem;
  background-color: rgba(44, 62, 80, 0.7);
  filter: drop-shadow(3px 3px 3px rgba(0,0,0,0.3));
  transition: all 0.3s ease-in-out;

  &:hover {
    color: #ffffff;
    transform: scale(1.01);
  }

  @media (max-width: 768px) {
    padding: 10px; 
  }
`;


