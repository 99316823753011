import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import About from '../components/About';
import DevModules from '../components/DevM';
import WebDev from '../components/Webdev';
import Services from '../components/Services';
import Sidebar from '../components/Sidebar';
import { Analytics } from '@vercel/analytics/react';

// eslint-disable-next-line no-unused-vars
const META_DATA = {
    title: "Development on OpenCart & React/Next.js",
    description: "Professional website and e-commerce development using OpenCart & React/Next.js. Custom modules, AI solutions and modern web development.",
    keywords: [
        "opencart development",
        "react/next.js development",
        "e-commerce solutions",
        "e-commerce websites",
        "custom modules",
        "opencart modules",
        "e-commerce modules",
        "AI agents",
        "AI solutions",
        "web development",
        "online store development",
        "modern web development"
    ].join(", "),
    author: "Opdev",
    siteUrl: "https://opdev.eu.org"
};

const Home = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };    

    return (
        <>
         <Sidebar isOpen={isOpen} toggle={toggle} />
         <Navbar toggle={toggle} />
         <Hero />
         <About />
         <DevModules />
         <WebDev />
         <Services />
         <Footer />
         <Analytics />                                     
        </>
    );
};

export default Home;
