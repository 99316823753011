import styled from 'styled-components';

export const ServicesSection = styled.section`
  padding: 4rem 0;
  background-color: #9baebc;
  @media (min-width: 768px) {
    padding: 5rem 0;
  }
  @media (min-width: 1024px) {
    padding: 7rem 0;
  }
`;

export const ServicesTitle = styled.div`
  text-align: center;
  margin-bottom: 70px;
  width: 100%;

  h2 {
    font-size: 2rem;
    color: #fff;
  }

  p {
    font-size: 1rem;
    color: #1c2d38;
  }
`;

export const ServicesContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  
  @media screen and (min-width: 640px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const CardContainer = styled.div`
  perspective: 1000px;
  min-height: 300px;
  cursor: pointer;
`;

export const ServiceCard = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 300px;
  transform-style: preserve-3d;
  transition: transform 0.8s;
  transform: ${({ isFlipped }) => isFlipped ? 'rotateY(180deg)' : 'rotateY(0)'};
`;

export const CardFront = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  background: #1c2d38;
  border-radius: 10px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  filter: drop-shadow(2px 2px 2px rgba(0,0,0,0.3));
`;

export const CardBack = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  background: #2c3e50;
  border-radius: 10px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  transform: rotateY(180deg);
  filter: drop-shadow(3px 3px 3px rgba(0,0,0,0.3));
`;

export const ServiceIcon = styled.div`
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  color: #9baebc;
  font-size: 2rem;
`;

export const ServiceTitle = styled.h3`
  color: #fff;
  font-size: 1rem;
  margin-bottom: 1rem;
`;

export const ServiceDescription = styled.p`
  color: #b5c7d6;
  font-size: 0.9rem;
  line-height: 1.5;
`;

export const ClickHint = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 5px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.8rem;
  transition: all 0.3s ease;

  svg {
    transition: transform 0.3s ease;
  }

  ${CardFront}:hover & {
    color: rgba(255, 255, 255, 0.9);
    
    svg {
      transform: translateX(5px);
    }
  }
`;